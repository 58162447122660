<template>
  <VueFilePond
    name="file"
    ref="pond"
    @updatefiles="updateFiles"
    @removefile="removeFile"
    v-bind="computedConfig"
    :files="initFileList"
    v-bind:server="imageUploadServer"
    :class="{'error__highlight': error }"
    :disabled="disabled || formAlreadySubmitted"
  />
  <!--  
    @removefile="removeFile"
    @click.native="$emit('focus', $event)" 
    :files="fileList"  
    :server="imageUploadServer" -->

</template>

<script>
import VueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import FilePondPluginGetFile from 'filepond-plugin-get-file';
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';
import axios from "@/axios";
import store from "../../store";
// import { isJson } from '../../utils';
// import VueCookies from "vue-cookies";

// import { EventBus } from "@/main.js";

export default {
  props: {
    fieldData: {
      type: Object,
      require: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    formAlreadySubmitted: {
      type: Boolean,
    }
  },
  components: {
    VueFilePond: VueFilePond(
      FilePondPluginFileValidateType,
      FilePondPluginImagePreview,
      FilePondPluginPdfPreview,
      FilePondPluginGetFile,
    ),
  },
  data() {
    return {
      config: {
        'label-idle': "Drag and drop or click here to upload a screenshot or PDF...",
        'credits': 'false',
        'allow-multiple': true,
        // 'accepted-file-types': "image/jpeg, image/png, application/pdf",
      },
      case_id:null, 
      token:null,
      imageUploadServer: {
        process:this.addFile,
        load: this.loadFile,
        revert: null
      },
      openImageURL: "",
      initFileList: [], // used only for initial file list
      fileList: [], // used for handling current value
      screenshot: {},
      dummydata: {},
    };
  },
  computed: {
    caseId() {
      return store.case_id;
    },
    computedConfig() {
      return {...this.config, ...this.settings};
    },
    multi() {
      return this.computedConfig['allow-multiple'];
    },
    outputValue() {
      // return JSON.stringify(this.fileList);
      return this.fileList.join(",")
    },
  },
  async mounted() {

    const mediaQuery = window.matchMedia('(orientation: landscape)');

    if (mediaQuery.matches && window.innerWidth < 1080) {
      this.config['label-idle'] = "click here to upload a screenshot or PDF..." ;
    } else if(window.innerWidth < 769){
      this.config['label-idle'] = "click here to upload a screenshot or PDF...";
    }

    mediaQuery.addListener((event) => {
      if (event.matches && window.innerWidth < 1080) {
        this.config['label-idle'] = "click here to upload a screenshot or PDF..." ;
      } else if(window.innerWidth < 769){
        this.config['label-idle'] = "click here to upload a screenshot or PDF..." ;
      }
    });

    // on the case page we have different case id param name:
      this.case_id = this.caseId || this.$route.query.case_id || this.$route.query.caseid; // TODO
      let temp_case_id = this.case_id?.split(",")
      this.case_id = temp_case_id ? temp_case_id[0] : "";
      this.token = this.$route.query.access_token;
    // const token = VueCookies.get("token");
    // this.imageUploadServer.process.headers["Authorization"] = `Bearer ${token}`;
    // if (this.value && this.value.available) {
    //   this.screenshot = this.value;
    //   this.fileList = [
    //     { source: this.screenshot.location, options: { type: "local" } },
    //   ];
    // }
    // //  ? (this.screenshot = this.value) : (this.screenshot = { available: false });
    // const file = this.fieldData.value;
    const files = [];
    // if (isJson(this.fieldData.value)) files.push(...JSON.parse(this.fieldData.value));
    // else if (this.fieldData.value) files.push(this.fieldData.value);
    if (this.fieldData.value)
      files.push(...this.fieldData.value.split(","))
    
    // if(file){
    //   this.fileList.push({
    //     source:file, options:{type:"local"}
    //   })
    //   }
    // },
    this.initFileList = files.map(file => ({
      source: file,
      options:{type:"local"},
    }));
    this.fileList = files;
  },
  methods: {
    // processFile(error, file) {
    //   if (!error) {
    //     this.screenshot.available = true;
    //     this.screenshot["location"] = JSON.parse(file.serverId).file;
    //     this.screenshot["file_name"] = file.filename;
    //     this.screenshot["file_mime_type"] = file.fileType;
    //     this.screenshot["size"] = file.fileSize;
    //     this.valueSync = this.screenshot;
    //     this.$set(this.valueSync, this.screenshot);
    //   }
    // },
    async updateFiles(files) {
      this.$emit("handleFieldValueChnage", files);
      if (!files.length) {
        this.$emit('input', '');
        this.$emit("handleFieldValueChnage", null);
      }
    },
    // is used for loading initial files:
    async loadFile(source, load){
      try{
        const response = await axios.get(`/case/form/${this.case_id}/files/${source}`, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization':`Bearer ${this.token}`
          },
          responseType:"blob"
        })


        load(response.data)

      } catch(error) {
        console.log('file fetching error', error)
        // use to hide unavailable file:
        // this.initFileList = this.initFileList.filter(el => el.source !== source);
      }
    },
    // is used for adding new files
    async addFile(fieldname, file, metadata, load, error, progress, abort) {
      // const case_id = this.$route.query.case_id;
      const token = this.$route.query.access_token;
      let pathArray = this.$route.path.split("/");
      console.log(fieldname, metadata, error, abort)

      let formData = new FormData();

      formData.append('file', file);
      if(!pathArray.includes("preview")){
      try {
        const response = await axios({
          url:`/case/form/${this.case_id}/upload-file`,
          method:"post",
          data:formData, 
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization':`Bearer ${token}`
          },
          onUploadProgess:(e) => {
            progress(e.lengthComputable, e.loaded, e.total)
          }
        })
        // this.$emit('input', response.data.data.file_path);
        this.dummydata[file.lastModified] = response.data.data.file_path ;
        if (this.multi) this.fileList.push(response.data.data.file_path);
        else this.fileList = [response.data.data.file_path];
        this.$emit('input', this.outputValue);
        load(response.data.data.file_path)
      } catch (error) {
        console.log('file loading error', error);
        this.$refs.pond.removeFiles();
        this.$toast.error(error?.response?.data?.detail || "Error While creating report");
      }
      }
      if(pathArray.includes("preview")){
          load();
      }
    },
    removeFile(error, removedFile) {
      if(!removedFile.serverId) {
         let key =  this.dummydata[removedFile.file.lastModified]
         this.fileList.splice(this.fileList.indexOf(key),1) 
      }
      this.fileList = this.fileList.filter(file => file !== removedFile.serverId); // check if a prop is checked correctly
      this.$emit('input', this.outputValue);
      this.$emit("handleFieldValueChnage", this.outputValue);
    }

  },
};
</script>

<style lang="scss" scoped>
.error__highlight {
    ::v-deep {
        .filepond--drop-label {
            border: 1px solid rgb(220, 38, 38);
            border-radius: 4px;
        }
        label {
            color: rgb(220, 38, 38);
        }
    }
}
::v-deep {
  .filepond {
    // &--root {
    // }
    &--drop-label {
      min-height: 15rem;
    }
    &--item {
      list-style: none;
    }
    &--file {
      min-height: 50px;
    }
  }
  .filepond--file-action-button {
    &.filepond--action-remove-item {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  .filepond--download-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .filepond--file-info {
    margin-left: 5px;
  }
  .filepond--file-info-main {
    @apply text-sm;
  }
}
</style>